<template>
  <section class="section mt-md">
    <div class="container">
      <div class="row justify-content-center text-center mb-sm">
        <div class="col-lg-10">
          <h4 class="display-4">About Ayun!</h4>
        </div>
      </div>

      <div class="row row-grid justify-content-center align-items-center">
        <div class="col-lg-8">
          <small id="aboutus">
            Ayun! is that "comforting-feeling" Filipino word that we exclaim
            when we find something lost after a seemingly futile attempt at
            finding it. This is what we want our users to feel every time they
            log in—happy and less stressed. Focus on your daily routine, and
            leave the monitoring of all your RPE to us.
          </small>
          <br />
          <br />
          <small id="aboutus">
            Ayun! is an intuitive asset monitoring system that utilizes QR Codes
            and CLOUD TECHNOLOGY to transfer and track the accountability of RPE
            within your supply chain.
          </small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'about-us'
};
</script>

<style scoped>
p,
.p-aboutus {
  color: #32325d;
}

.section {
  background-color: #eaf1fc;
}
</style>
